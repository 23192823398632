import { Button, Space, message } from "antd";
import { EditorLayout } from "components/layouts/EditorLayout";
import {
  TemplateForm,
  TemplateFormHandles,
} from "components/template-form/TemplateForm";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const TemplatePage = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useDataProviderContext();
  const templateFormRef = useRef<TemplateFormHandles>(null);

  const handleSave = async () => {
    if (templateFormRef.current) {
      await templateFormRef.current.save();
      message.success("Blueprint saved successfully");
      navigate("/templates");
    }
  };

  return (
    <EditorLayout
      isLoading={isLoading}
      headerExtra={
        <Space>
          <Button onClick={() => navigate("/templates")}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
      title={templateId ? "Edit Blueprint" : "Create Blueprint"}
    >
      <TemplateForm ref={templateFormRef} />
    </EditorLayout>
  );
};
