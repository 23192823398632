import { Button, Card, Divider, Flex, Skeleton, Typography } from "antd";
import styles from "./TemplateCard.module.scss";
import { useNavigate } from "react-router-dom";
import { useDataProviderContext } from "contexts/DataProviderContext";
import classNames from "classnames";
import { TemplateModal } from "components/template-modal/TemplateModal";
import { useCallback, useState } from "react";
import { ConversationTemplateActions } from "components/conversation-template-actions/ConversationTemplateActions";

type TemplateCardProps = {
  iceBreaker: IceBreaker;
  heading?: string;
  sources?: DataSource[];
  subheading?: string;
};

export const SkeletonTemplateCard = () => (
  <Card className={styles.skeleton}>
    <div style={{ height: "100px" }}>
      <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
    </div>
  </Card>
);

export const TemplateCard = ({
  iceBreaker,
  heading = iceBreaker.name,
  sources = iceBreaker.dataSources,
  subheading = iceBreaker.description,
}: TemplateCardProps) => {
  const [openModel, setOpenModel] = useState(false);
  const { startConversation } = useDataProviderContext();
  const onCardClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      startConversation({ icebreaker: String(iceBreaker.id) });
    },
    [iceBreaker.id, startConversation],
  );

  const onButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenModel(true);
  };

  return (
    <>
      {openModel && (
        <TemplateModal
          open={openModel}
          icebreaker={iceBreaker}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setOpenModel(false);
          }}
        />
      )}
      <Card className={styles.card} onClick={onCardClick}>
        <div className={styles.cardHeader}>
          <Flex>
            <Typography.Title
              aria-hidden
              className={classNames(styles.heading)}
              level={3}
            >
              {heading}
            </Typography.Title>
            <Flex>
              <ConversationTemplateActions template={iceBreaker} />
            </Flex>
          </Flex>
          <Typography.Text className={styles.text}>
            {subheading}
          </Typography.Text>
        </div>
        <Divider />
        <Flex className={styles.cardFooter}>
          <Flex>
            <Typography.Text
              ellipsis
              style={{
                color: "#9F99C2",
              }}
            >
              {sources.length > 0 ? (
                <div>{sources.length} data source(s)</div>
              ) : null}
            </Typography.Text>
          </Flex>
          <Button className={styles.button} type="link" onClick={onButtonClick}>
            See Details
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export const ViewAllTemplates = () => {
  const { iceBreakers } = useDataProviderContext();
  const navigate = useNavigate();

  const primaryText =
    iceBreakers.length > 3 ? (
      <>
        <Flex className={classNames(styles.cardHeader, styles.viewAll)}>
          <Typography.Title aria-hidden level={3} className={styles.quantity}>
            {`+${iceBreakers.length - 3}`}
          </Typography.Title>
          <Typography.Text className={styles.text}>blueprints</Typography.Text>
        </Flex>
        <Divider />
      </>
    ) : (
      <></>
    );

  const primaryButton =
    iceBreakers.length > 3 ? "View all blueprints" : "Create new";

  const onClick = useCallback(() => {
    if (iceBreakers.length > 3) {
      navigate("/templates");
    } else {
      navigate("/templates/new");
    }
  }, [iceBreakers, navigate]);

  return (
    <Card
      className={classNames(styles.card, styles.viewAllCard)}
      onClick={onClick}
    >
      {primaryText}
      <Flex className={classNames(styles.cardFooter, styles.viewAllFooter)}>
        <Button
          type="link"
          className={classNames(styles.button, styles.viewAllButton)}
        >
          {primaryButton}
        </Button>
      </Flex>
    </Card>
  );
};
