import {
  ConversationViewEnum,
  FeedbackScopeEnum,
  FeedbackTypeEnum,
  RetrieveTypesEnum,
  Roles,
} from "../types/enum";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { NewtonApi } from "../utils/newtonApi";
import { conversationReducer } from "../reducers";

type DataProviderContextType = {
  activeConversation?: Conversation;
  additionalConversationsLoading: boolean;
  activeAnalyst?: Analyst;
  analysts: Analyst[];
  authenticated: boolean;
  conversations: Conversation[];
  datasources: DataSource[];
  filteredDatasources: { results: DataSource[]; loading: boolean | null };
  defaultAnalyst?: Analyst;
  iceBreakers: IceBreaker[];
  isLoading: boolean;
  isStreaming: boolean;
  messages: Message[];
  prompt: Partial<Message>;
  sharedEntities: ShareableEntity[];
  showLoadMoreConversations: boolean;
  me: UserEntity | undefined;
  view: ConversationViewEnum;
  feedbackSentimentsData: { results: Sentiment[]; loading: boolean | null };
  cleanConversationDataSources: boolean;
  conversationDatasources: {
    results: MessageDataSources[];
    loading: boolean | null;
  };
  feedbackType: FeedbackTypeEnum | undefined;
  isActiveConversationLoading: boolean | null;
  archiveConversation: (conversation: Conversation) => void;
  archiveIceBreaker: (iceBreaker: IceBreaker) => void;
  createIceBreaker: (iceBreaker: NewIceBreaker) => Promise<IceBreaker>;
  copyIceBreaker: (iceBreaker: UpdateIceBreaker) => Promise<IceBreaker>;
  copyConversation: (id: ConversationId) => void;
  deleteConversation: (conversation: Conversation) => void;
  deleteIceBreaker: (iceBreaker: IceBreaker) => void;
  isConvoStreaming: (conversationId: ConversationId) => boolean;
  loadMoreConversations: () => void;
  getConversationEntities(
    conversation_id: ConversationId,
  ): Promise<ShareableEntity[]>;
  getUnreadMessages: (conversationId: ConversationId) => MessageId[];
  goToConversation: (c: Conversation) => void;
  newMessage: (message: Message) => Promise<{ sessionId: string }>;
  rewindConversation: (conversation: Conversation, message: Message) => void;
  shareConversation: (
    conversationId: ConversationId,
    users: ShareableEntity[],
  ) => Promise<void>;
  setAuthenticated: (authenticated: boolean) => void;
  setPrompt: (message: Partial<Message>) => void;
  setView: (view: ConversationViewEnum) => void;
  startConversation: (params?: Record<string, string>) => void;
  stopConversation(sessionId: string): Promise<void>;
  updateConversation: (conversation: Conversation) => void;
  updateIceBreaker: (iceBreaker: UpdateIceBreaker) => Promise<IceBreaker>;
  uploadDatasourceFile: (file: FormData) => Promise<DataSource>;
  setRetrieveType: (type: RetrieveType) => void;
  filterDatasources: (query: RetrieveType) => void;
  cleanFilterDatasources: () => void;
  downloadFileByDisplayObject: (displayObject: DisplayObject) => Promise<void>;
  generateDefaultDescription: (form: FormData) => Promise<string>;
  createFeedback: (feedback: Feedback) => Promise<void>;
  uploadSecretData: (file: FormData) => Promise<void>;
  fetchSingleConversation: (
    conversationId: ConversationId,
    feedbackScope?: FeedbackScopeEnum,
  ) => Promise<void>;
  setCleanConversationDataSources: (value: boolean) => void;
  fetchSingleMessage: (
    messageId: MessageId,
    conversationId: ConversationId,
    feedbackScope?: FeedbackScopeEnum,
  ) => Promise<void>;
  setFeedbackType: (type: FeedbackTypeEnum) => void;
  deleteDataSourceById: (id: number) => Promise<void>;
};

const DataProviderContext = createContext<DataProviderContextType>(
  {} as DataProviderContextType,
);

const ViewToRole = {
  [ConversationViewEnum.ALL]: [Roles.USER, Roles.ASSISTANT, Roles.USER_PROXY],
  [ConversationViewEnum.EXECUTIVE]: [Roles.USER, Roles.SUMMARY],
};

export const DataProviderContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [prompt, setPrompt] = useState<Partial<Message>>({});
  const [view, setView] = useState<ConversationViewEnum>(
    ConversationViewEnum.EXECUTIVE,
  );
  const [cleanConversationDataSources, setCleanConversationDataSources] =
    useState<boolean>(false);
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const [retrieveType, setRetrieveType] = useState<RetrieveType>(
    RetrieveTypesEnum.ALL,
  );

  const [state, dispatch] = useReducer(conversationReducer, {
    activeStreams: {},
    analysts: [],
    defaults: {
      analyst: undefined,
    },
    loaded: false,
    conversations: {
      data: [],
      loading: true,
      pagination: { count: 0, next: null, previous: null },
    },
    datasources: [],
    filteredDatasources: { results: [], loading: null },
    feedbackSentiments: { results: [], loading: null },
    iceBreakers: [],
    sharedEntities: [],
    unread: {},
    user: undefined,
    newActiveConversation: { data: undefined, loading: null },
    conversationDatasources: { results: [], loading: null },
  });

  const generateConversation = useCallback(
    async (
      icebreaker?: string | undefined,
      text = "New Conversation",
    ): Promise<Conversation> => {
      const date = new Date();
      const convo = {
        createdAt: date.toISOString(),
        datasources: [],
        description: text,
        name: text.substring(0, 30),
        messageSet: [],
        updatedAt: date.toISOString(),
        owner: state.user?.id,
        icebreaker: Number(icebreaker) ?? null,
      };

      return await NewtonApi.createConversation(convo);
    },
    [state.user],
  );

  const showLoadMoreConversations = useMemo(
    () => state.conversations.pagination.next !== null,
    [state.conversations.pagination.next],
  );

  const loadMoreConversations = useCallback(async () => {
    if (state.conversations.pagination.next) {
      const urlObj = new URL(state.conversations.pagination.next);
      const urlSearch = new URLSearchParams(urlObj.search);
      const params: Record<string, string> = {};

      Array.from(urlSearch.entries()).forEach(([key, value]) => {
        params[key] = value;
      });
      dispatch({ type: "FETCH_CONVERSATIONS" });
      const { results: additionalConversations, ...pagination } =
        await NewtonApi.fetchAllConversations(params);
      dispatch({
        type: "FETCHED_ADDITIONAL_CONVERSATIONS",
        payload: {
          conversations: additionalConversations,
          pagination,
        },
      });
    }
  }, [state.conversations.pagination]);

  const additionalConversationsLoading = useMemo(() => {
    return state.conversations.loading;
  }, [state.conversations.loading]);

  const conversations = useMemo(
    () => state.conversations.data,
    [state.conversations.data],
  );

  useEffect(() => {
    (async () => {
      if (!authenticated) return;
      // Load the user first for UX
      const me = await NewtonApi.fetchUser();
      dispatch({ type: "FETCH_USER", payload: me });

      const [
        myConversations,
        // sharedConversations,
        iceBreakers,
        sources,
        entities,
        analysts,
        defaultAnalyst,
        sentiments,
      ] = await Promise.all([
        NewtonApi.fetchAllConversations({
          sort: "-updated_at",
          retrieve_type: "all",
        }),
        NewtonApi.fetchAllIceBreakers(),
        NewtonApi.fetchAllDataSources(retrieveType),
        NewtonApi.fetchShareableEntities(),
        NewtonApi.fetchAllAnalysts(),
        NewtonApi.fetchDefaultAnalyst(),
        NewtonApi.fetchFeedbackSentiments(),
      ]);

      const { results: conversations, ...pagination } = myConversations;

      dispatch({ type: "FETCH_ICEBREAKERS", payload: iceBreakers.results });
      dispatch({ type: "FETCH_DATASOURCES", payload: sources.results });
      dispatch({ type: "FETCH_SHAREABLE_ENTITIES", payload: entities.results });
      dispatch({ type: "FETCH_ANALYSTS", payload: analysts.results });
      dispatch({ type: "FETCH_DEFAULT_ANALYST", payload: defaultAnalyst });
      dispatch({
        type: "FETCHED_CONVERSATIONS",
        payload: {
          conversations: conversations,
          pagination,
        },
      });
      dispatch({ type: "FETCH_ALL_SENTIMENTS", payload: sentiments.results });
    })();
  }, [dispatch, authenticated]);

  const filterDatasources = useCallback(async (query: RetrieveType) => {
    dispatch({ type: "FILTER_DATASOURCES_REQUEST" });
    const datasources = await NewtonApi.fetchAllDataSources(query);
    dispatch({ type: "FILTER_DATASOURCES", payload: datasources.results });
  }, []);

  const cleanFilterDatasources = useCallback(() => {
    dispatch({ type: "FILTER_DATASOURCES_CLEAN" });
  }, [dispatch]);

  useEffect(() => {
    if (conversationId) {
      dispatch({
        type: "ACTIVE_CONVERSATION",
        payload: conversationId as ConversationId,
      });
    }
  }, [conversationId]);

  const goToConversation = useCallback(
    (c: Conversation, params?: Record<string, string>) => {
      let attrs = "";
      if (Object.keys(params || {}).length) {
        attrs = Object.entries(params || {})
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          )
          .join("&");
      }

      navigate(`/c/${c.id}${attrs ? `?${attrs}` : ""}`);
    },
    [navigate],
  );

  const copyConversation = useCallback(
    async (id: ConversationId) => {
      const convo = await NewtonApi.copyConversation(id);
      dispatch({ type: "ADD_CONVERSATION", payload: convo });
      goToConversation(convo);
    },
    [dispatch, goToConversation],
  );

  const findAnalyst = useCallback(
    (name: string) => {
      return state.analysts.find((a) => a.name === name);
    },
    [state.analysts],
  );
  const [feedbackType, setFeedbackType] = useState<FeedbackTypeEnum>();

  useEffect(() => {
    (async () => {
      if (!conversationId) {
        dispatch({
          type: "NEW_ACTIVE_CONVERSATION_CLEAN",
        });
        dispatch({
          type: "CONVERSATION_DATASOURCES_CLEAN",
        });
        return;
      }

      dispatch({
        type: "NEW_ACTIVE_CONVERSATION_REQUEST",
      });
      dispatch({
        type: "CONVERSATION_DATASOURCES_REQUEST",
      });

      try {
        const [conversation, dataSources] = await Promise.all([
          NewtonApi.fetchConversation(conversationId as ConversationId, {
            feedback_scope: FeedbackScopeEnum.USER,
          }),
          NewtonApi.fetchConversationDataSources(
            conversationId as ConversationId,
          ),
        ]);

        if (conversation?.id) {
          dispatch({
            type: "NEW_ACTIVE_CONVERSATION",
            payload: conversation,
          });
          dispatch({
            type: "CONVERSATION_DATASOURCES",
            payload: dataSources,
          });
        } else {
          navigate("/c");

          dispatch({
            type: "NEW_ACTIVE_CONVERSATION_CLEAN",
          });
          dispatch({
            type: "CONVERSATION_DATASOURCES_CLEAN",
          });
        }
      } catch (error) {
        navigate("/c");
      }
    })();
  }, [state.conversations.data, conversationId]);

  const activeConversation = useMemo(() => {
    // id's are numbers not strings use `== `

    return state.newActiveConversation.data;
  }, [state.newActiveConversation, conversationId]);

  const isActiveConversationLoading = useMemo(() => {
    return state.newActiveConversation.loading;
  }, [state.newActiveConversation, conversationId]);

  const activeAnalyst = useMemo(() => {
    if (!activeConversation) return undefined;
    const name =
      activeConversation?.messageSet[activeConversation?.messageSet.length - 1]
        ?.analyst;
    return name ? findAnalyst(name) : undefined;
  }, [activeConversation, state.analysts, findAnalyst]);

  const newMessage = useCallback(
    async (message: Message) => {
      const conversationId = (message.conversationId ||
        activeConversation!.id) as ConversationId;

      // Analyst is now always sent on messages. We base determine it in the following order.
      // 1. User selection
      // 2. Prior message
      // 3. Default analyst
      const analystId =
        message.analystId || activeAnalyst?.id || state.defaults.analyst?.id;
      const blankConvo = activeConversation?.messageSet.length === 0;
      dispatch({
        type: "STREAMING_START",
        payload: conversationId,
      });

      return await NewtonApi.newMessage(
        {
          ...message,
          conversationId,
          parentMessageId:
            activeConversation?.messageSet.findLast((x) => x)?.id || null,
          analystId: analystId,
        },
        {
          onMessage: async (incomingMessage: Message) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { dataSources, ...rest } = incomingMessage;

            dispatch({
              type: "NEW_MESSAGE",
              payload: rest,
            });

            if (incomingMessage.role === "user") {
              dispatch({
                type: "UPDATE_CONVERSATION_DATASOURCES",
                payload: {
                  id: incomingMessage.id as MessageId,
                  dataSources: incomingMessage.dataSources as DataSource[],
                },
              });
            }

            // If this is a new convo, fetch details for the convo

            if (blankConvo && incomingMessage.role === "user") {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { messageSet, dataSources, ...rest } =
                await NewtonApi.fetchConversation(conversationId);
              dispatch({
                type: "UPDATE_PARTIAL_CONVERSATION",
                payload: {
                  ...rest,
                },
              });
            }
          },
          onComplete: () => {
            dispatch({
              type: "STREAMING_COMPLETE",
              payload: conversationId,
            });
          },
        },
      );
    },
    [
      activeConversation,
      activeAnalyst,
      conversationId,
      state.user,
      state.activeConversationId,
    ],
  );

  const deleteConversation = useCallback(
    async (conversation: Conversation) => {
      try {
        await NewtonApi.deleteConversation(conversation.id);
        dispatch({ type: "DELETE_CONVERSATION", payload: conversation.id });
        navigate("/c");
      } catch (e) {
        console.error(e);
        alert("Failed to delete conversation");
      }
    },
    [navigate, conversationId, dispatch, state.conversations.data],
  );

  const archiveIceBreaker = useCallback(
    async (iceBreaker: IceBreaker) => {
      try {
        await NewtonApi.archiveIceBreaker(iceBreaker.id);
        dispatch({ type: "ARCHIVE_ICEBREAKER", payload: iceBreaker.id });
      } catch (e) {
        console.error(e);
        alert("Failed to archive icebreaker");
      }
    },
    [navigate, dispatch],
  );

  const deleteIceBreaker = useCallback(
    async (iceBreaker: IceBreaker) => {
      try {
        await NewtonApi.deleteIceBreaker(iceBreaker.id);
        dispatch({ type: "DELETE_ICEBREAKER", payload: iceBreaker.id });
      } catch (e) {
        console.error(e);
        alert("Failed to delete icebreaker");
      }
    },
    [navigate, dispatch, state.iceBreakers],
  );

  const archiveConversation = useCallback(
    async (conversation: Conversation) => {
      try {
        await NewtonApi.archiveConversation(conversation.id);
        dispatch({ type: "ARCHIVE_CONVERSATION", payload: conversation.id });
      } catch (e) {
        console.error(e);
        alert("Failed to archive conversation");
      }
    },
    [navigate, conversationId, dispatch],
  );

  const startConversation = useCallback(
    async (params?: Record<string, string>) => {
      const payload = await generateConversation(params?.icebreaker);

      dispatch({
        type: "ADD_CONVERSATION",
        payload,
      });
      goToConversation(payload, params);
    },
    [dispatch, generateConversation, goToConversation],
  );

  const updateConversation = useCallback(
    async (payload: Conversation) => {
      try {
        const convo = await NewtonApi.updateConversation(payload);

        dispatch({
          type: "UPDATE_CONVERSATION",
          payload: convo,
        });
      } catch (e) {
        console.error(e);
        alert("Failed to update conversation");
      }
    },
    [dispatch],
  );

  const rewindConversation = useCallback(
    (conversation: Conversation, message: Message) => {
      try {
        NewtonApi.rewindConversation(conversation, message);
        const messageIndex = conversation.messageSet.findIndex(
          (m) => m.id === message.id,
        );
        dispatch({
          type: "REWIND_CONVERSATION",
          payload: messageIndex,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch],
  );

  const messages = useMemo(() => {
    if (!activeConversation) return [];

    return activeConversation.messageSet.filter((m) => {
      return ViewToRole[view].includes(m.role as Roles);
    });
  }, [activeConversation, activeConversation?.messageSet.length, view]);

  const isStreaming = useMemo(() => {
    return activeConversation
      ? state.activeStreams[activeConversation.id] || false
      : false;
  }, [state.activeStreams, activeConversation]);

  const isConvoStreaming = useCallback(
    (conversationId: ConversationId) => {
      return state.activeStreams[conversationId] || false;
    },
    [state.activeStreams],
  );

  const getUnreadMessages = useCallback(
    (conversationId: ConversationId) => {
      return state.unread[conversationId] || [];
    },
    [state.unread],
  );

  const getConversationEntities = useCallback(
    async (conversation_id: ConversationId) => {
      const { results: entities } = await NewtonApi.fetchConversationEntities({
        conversation_id,
      });
      return entities;
    },
    [],
  );

  const uploadDatasourceFile = useCallback(
    async (form: FormData) => {
      const res = await NewtonApi.uploadMemoryFile(form);
      const datasources = await NewtonApi.fetchAllDataSources(retrieveType);
      dispatch({ type: "FETCH_DATASOURCES", payload: datasources.results });

      return res;
    },
    [dispatch],
  );

  const copyIceBreaker = useCallback(
    async (iceBreaker: UpdateIceBreaker) => {
      const newIceBreaker = await NewtonApi.copyIceBreaker(iceBreaker);
      dispatch({ type: "ADD_ICEBREAKER", payload: newIceBreaker });
      return newIceBreaker;
    },
    [dispatch],
  );

  const createIceBreaker = useCallback(
    async (iceBreaker: NewIceBreaker) => {
      const newIceBreaker = await NewtonApi.createIceBreaker(iceBreaker);
      dispatch({ type: "ADD_ICEBREAKER", payload: newIceBreaker });
      return newIceBreaker;
    },
    [dispatch],
  );

  const updateIceBreaker = useCallback(
    async (iceBreaker: UpdateIceBreaker) => {
      const newIceBreaker = await NewtonApi.updateIceBreaker(iceBreaker);
      dispatch({ type: "UPDATE_ICEBREAKER", payload: newIceBreaker });
      return newIceBreaker;
    },
    [dispatch],
  );

  const downloadFileByDisplayObject = useCallback(
    async (displayObject: DisplayObject) => {
      return NewtonApi.downloadDisplayObject(displayObject);
    },
    [],
  );
  const generateDefaultDescription = useCallback(async (form: FormData) => {
    const { description } = await NewtonApi.createFileDescription(form);
    return description;
  }, []);

  const allSentiments = useMemo(() => {
    return state.feedbackSentiments;
  }, [state.feedbackSentiments]);

  const createFeedback = useCallback(async (feedback: Feedback) => {
    const { message, ...rest } = feedback;
    return await NewtonApi.sendFeedback(rest, message);
  }, []);

  const uploadSecretData = useCallback(async (form: FormData) => {
    const res = await NewtonApi.uploadSecretFile(form);
    return res;
  }, []);

  const fetchSingleConversation = useCallback(
    async (
      conversationId: ConversationId,
      feedbackScope?: FeedbackScopeEnum,
    ) => {
      const convo = await NewtonApi.fetchConversation(conversationId, {
        ...(feedbackScope && { feedback_scope: feedbackScope }),
      });
      dispatch({ type: "UPDATE_CONVERSATION", payload: convo });
    },
    [dispatch],
  );

  const fetchSingleMessage = useCallback(
    async (
      messageId: MessageId,
      conversationId: ConversationId,
      feedbackScope?: FeedbackScopeEnum,
    ) => {
      const message = await NewtonApi.fetchMessage(messageId, feedbackScope);

      dispatch({
        type: "UPDATE_MESSAGE",
        payload: { message, conversationId },
      });
    },
    [dispatch],
  );

  const deleteDataSourceById = useCallback(async (id: number) => {
    await NewtonApi.deleteDatasource(id as DataSourceId);
    dispatch({ type: "DELETE_DATASOURCE", payload: id });
  }, []);

  return (
    <DataProviderContext.Provider
      value={{
        archiveConversation,
        activeConversation,
        activeAnalyst,
        additionalConversationsLoading,
        analysts: state.analysts,
        authenticated,
        conversations,
        datasources: state.datasources,
        filteredDatasources: state.filteredDatasources,
        defaultAnalyst: state.defaults.analyst,
        iceBreakers: state.iceBreakers,
        isLoading: !state.loaded,
        isStreaming,
        me: state.user,
        messages,
        prompt,
        sharedEntities: state.sharedEntities,
        showLoadMoreConversations,
        view,
        feedbackSentimentsData: allSentiments,
        cleanConversationDataSources,
        conversationDatasources: state.conversationDatasources,
        feedbackType,
        isActiveConversationLoading,
        archiveIceBreaker,
        createIceBreaker,
        copyIceBreaker,
        copyConversation,
        deleteConversation,
        deleteIceBreaker,
        isConvoStreaming,
        loadMoreConversations,
        getConversationEntities,
        getUnreadMessages,
        goToConversation,
        newMessage,
        rewindConversation,
        setPrompt,
        setView,
        shareConversation: NewtonApi.shareConversation,
        startConversation,
        stopConversation: NewtonApi.stopConversation,
        updateConversation,
        updateIceBreaker,
        uploadDatasourceFile,
        setAuthenticated,
        setRetrieveType,
        filterDatasources,
        cleanFilterDatasources,
        downloadFileByDisplayObject,
        generateDefaultDescription,
        createFeedback,
        uploadSecretData,
        fetchSingleConversation,
        setCleanConversationDataSources,
        fetchSingleMessage,
        setFeedbackType,
        deleteDataSourceById,
      }}
    >
      {children}
    </DataProviderContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDataProviderContext = () => {
  const context = useContext(DataProviderContext);

  if (!context) {
    console.error(
      "useDataProviderContext must be used within a DataProviderContextProvider",
    );
  }

  return context;
};
