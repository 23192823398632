import { Button, Card, Divider, Flex, Switch, Tooltip, Typography } from "antd";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { formatDate } from "utils/formatDate";
import styles from "./DatasourceCard.module.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { ConfirmationModal } from "../common/ConfirmationModal";
import { useState } from "react";

type DatasourceCardProps = {
  datasource: DataSource;
  disabled?: boolean;
  isSelected?: boolean;
  onToggle?(ds: DataSource): void;
};

export const DatasourceCard = ({
  datasource,
  disabled = false,
  isSelected,
  onToggle,
}: DatasourceCardProps) => {
  const { deleteDataSourceById } = useDataProviderContext();
  const { id, createdAt, name, description, isDeletable } = datasource;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = (id: number) => {
    deleteDataSourceById(id);
    setIsModalOpen(false);
  };

  return (
    <Card className={styles.datasourceCard}>
      <ConfirmationModal
        isOpen={isModalOpen}
        title="Delete data source"
        description={`Are you sure you want to delete data source ${name}? It will be removed from all conversations where it is in use.`}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={() => handleDelete(id)}
        confirmColor="red"
      />
      <div style={{ height: "132px" }}>
        <Flex justify="space-between">
          <Typography.Title
            aria-hidden
            level={3}
            style={{
              color: "var(--nri-color-primary)",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              letterSpacing: "0.01em",
            }}
          >
            {name}
          </Typography.Title>
          {onToggle && (
            <Switch
              checked={isSelected}
              disabled={disabled}
              onChange={() => onToggle(datasource)}
            />
          )}
        </Flex>
        <Typography.Text className={styles.description}>
          {description}
        </Typography.Text>
      </div>
      <Divider />
      <Flex justify="space-between" align="center" style={{ height: "44px" }}>
        {createdAt && (
          <div style={{ color: "#9F99C2" }}>
            Created: {formatDate(createdAt)}
          </div>
        )}
        {isDeletable && (
          <Button
            type="text"
            className={styles.deleteButton}
            icon={
              <Tooltip title="Delete data source">
                <DeleteOutlined style={{ fontSize: "18px" }} />
              </Tooltip>
            }
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </Flex>
    </Card>
  );
};

export const ViewAllTemplates = () => {
  const { iceBreakers } = useDataProviderContext();
  return (
    <Card>
      <Typography.Title
        aria-hidden
        level={3}
        style={{
          color: "var(--nri-color-primary)",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          letterSpacing: "0.01em",
        }}
      >
        +{iceBreakers.length}
      </Typography.Title>
      <Typography.Text
        style={{
          color: "#787878",
          fontSize: "14px",
          lineHeight: "21px",
        }}
      >
        Blueprints
      </Typography.Text>
      <Divider />
      View all blueprints
    </Card>
  );
};
