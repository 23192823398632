import {
  Button,
  Flex,
  Input,
  Layout,
  Skeleton,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";

import BookmarkIcon from "../../assets/icons/bookmarkFolder.svg?react";
import { ConversationViewEnum } from "../../types/enum";
import InfoIcon from "../../assets/icons/info.svg?react";
import PencilIcon from "../../assets/icons/edit.svg?react";
import { SlideInText } from "components/animations/SlideInText";
import styles from "./ConversationView.module.scss";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { useNavigate } from "react-router-dom";
import { validateText } from "utils/validateText";

export const ConversationHeader: React.FC = () => {
  const navigate = useNavigate();
  const { activeConversation, isLoading, setView, updateConversation, view } =
    useDataProviderContext();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string | undefined>("");

  useEffect(() => {
    activeConversation?.name && setNewTitle(activeConversation?.name);
  }, [activeConversation]);

  const updateTitle = useCallback(
    (title?: string) => {
      if (title) {
        setNewTitle(title);
        updateConversation({
          ...(activeConversation as Conversation),
          name: title,
        });
      } else {
        setNewTitle(activeConversation?.name || "");
      }
      setIsEditing(false);
    },
    [activeConversation, setNewTitle, setIsEditing, updateConversation],
  );

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.title_wrapper}>
          {isLoading ? (
            <Skeleton.Button active className={styles.skeleton} />
          ) : activeConversation ? (
            <>
              {isEditing ? (
                <Typography.Title className={styles.title} level={3}>
                  <Input
                    className={styles.input}
                    variant="filled"
                    value={newTitle}
                    type="text"
                    maxLength={30}
                    onChange={(e) => setNewTitle(validateText(e.target.value))}
                    onPressEnter={() => updateTitle(newTitle)}
                  />
                </Typography.Title>
              ) : (
                <SlideInText
                  key={activeConversation.updatedAt}
                  className={styles.title}
                  text={activeConversation.name || ""}
                  level={3}
                />
              )}

              {isEditing ? (
                <>
                  <CheckIcon
                    className={styles.edit_icon}
                    height={24}
                    onClick={() => updateTitle(newTitle)}
                  />
                  <XMarkIcon
                    className={styles.edit_icon}
                    color="red"
                    height={24}
                    onClick={() => updateTitle()}
                  />
                </>
              ) : (
                <PencilIcon
                  className={styles.edit_icon}
                  height={24}
                  display={
                    activeConversation.messageSet.length >= 2
                      ? "initial"
                      : "none"
                  }
                  onClick={() => setIsEditing(!isEditing)}
                />
              )}
            </>
          ) : null}
        </div>
        <Tabs
          activeKey={view}
          className={styles.tabs}
          items={[
            {
              key: ConversationViewEnum.EXECUTIVE,
              label: "Summary Conversation",
            },
            {
              key: ConversationViewEnum.ALL,
              label: "Analytics Conversation",
            },
          ]}
          onChange={(view) => setView(view as ConversationViewEnum)}
        />
      </div>
      <Flex align="center" justify="end">
        {activeConversation && (
          <>
            <Tooltip title="Information">
              <Button
                disabled
                icon={<InfoIcon />}
                style={{
                  boxShadow: "none",
                  marginLeft: "8px",
                  height: 44,
                  width: 44,
                }}
              ></Button>
            </Tooltip>

            <Tooltip title="Add to favorites">
              <Button
                disabled
                icon={<BookmarkIcon />}
                style={{
                  boxShadow: "none",
                  marginLeft: "8px",
                  height: 44,
                  width: 44,
                }}
              ></Button>
            </Tooltip>
          </>
        )}
        <Button
          onClick={() => {
            navigate("/templates/new?conversationId=" + activeConversation?.id);
          }}
          style={{
            boxShadow: "none",
            marginLeft: "8px",
            height: 44,
          }}
        >
          Save as Blueprint
        </Button>
      </Flex>
    </Layout.Header>
  );
};
